<template>
  <div>
    <el-popconfirm
      v-if="$canAndRulesPass(row, 'card.delete')"
      confirm-button-text='Да'
      cancel-button-text='нет'
      icon="el-icon-question"
      title="Вы уверены, что хотите удалить карту?"
      @confirm="$emit('delete-card', row)"
    >
      <el-button
        style="margin-right: 10px"
        slot="reference"
        class="table-action-button"
        type="default"
        round
        size="mini"
        icon="fas fa-trash"
      >
      </el-button>
    </el-popconfirm>
    <el-tooltip content="Перейти" placement="top" :open-delay="500">
      <el-button
        class="table-action-button"
        type="default"
        round
        size="mini"
        icon="fas fa-external-link-alt"
        @click="$router.push({ name: 'Card', params: { id: row.id } })"
      >
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>

export default {
  name: "router-link-to-card-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style>

</style>